import { Button, Form } from 'react-bootstrap';
import Survey from './Survey.json';
import { useState } from 'react';

function Question(props) {

    const [selected, setSelected] = useState();

    const goNext = () => {
        props.goToNext(selected)
    }

    const storeScore = (weight) => {
        return (evt) => {
            if (evt.target.checked) {
                console.log(evt.target.value)
                setSelected(props.answers[evt.target.value])
                props.updateScore(props.id, weight)
            }
        }
    }

    return (
        <div>
            <Form.Label>
                {props.prompt}
            </Form.Label>
            {Object.keys(props.answers).map((a) => (
                <Form.Check
                    key={a}
                    type="radio"
                    label={a}
                    value={a}
                    name={`${props.id}-Answer`}
                    onChange={storeScore(props.answers[a].weight)}
                    id={`${props.id}-Answer-${a}`} />
            ))}
            <Button
                onClick={props.startOver}>Start Over</Button>
            <Button
                variant="primary"
                disabled={!selected}
                onClick={goNext}>Next</Button>
        </div>
    )
}

function LastQuestion(props) {

    let totalScore = [...props.scores.keys()]
        .map(k => props.scores.get(k))
        .reduce((s, n) => s + n, 0);

    let rating = Survey.ScoreRanges
        .find(sr => totalScore >= sr.lower && totalScore <= sr.upper)
        

    return (
        <div>
            Your score: {rating.rating}
            <br />
            Next Steps: {rating.message}
        </div>
    )
}

function QuestionForm() {
    const firstQuestion = Survey.Questions.filter(q => q.first).pop();

    const [scores, setScores] = useState(new Map());
    const [isLastQuestion, setLastQuestion] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(firstQuestion);


    const goToNextQuestion = (a) => {
        if (a.nextQ === undefined) {
            setLastQuestion(true);
            return
        }
        setCurrentQuestion(Survey.Questions.filter(q => q.id === a.nextQ).pop());
    }

    const startOver = () => {
        setScores(new Map());
        setCurrentQuestion(firstQuestion);
    }

    const updateScore = (q, score) => {
        setScores(new Map(scores.set(q, score)))
    }

    if (isLastQuestion) {
        return (
            <div>
                <LastQuestion scores={scores}/>
            </div>
        )
    }
    return (
        <div>
            <Form>
                <Question
                    key={currentQuestion.id}
                    id={currentQuestion.id}
                    prompt={currentQuestion.prompt}
                    answers={currentQuestion.answers}
                    updateScore={updateScore}
                    goToNext={goToNextQuestion}
                    startOver={startOver}
                />
            </Form>
        </div>
    )
}

export default QuestionForm;